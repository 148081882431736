* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
  font-size: 16px;
}
h1 {
  font-weight: normal;
  font-size: 1.3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  background-color: black;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: auto;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  display: none; /* Hide the nav links by default */
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  display: block; /* Show the nav links when the .show-nav class is added */
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    overflow: visible;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 4;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.carsHero {
  height: calc(100vh - 66px);
  width: 100%;
  background: url("./images/exoticcar3.jpg") ;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}

.carHero {
  background-image: url("./images/exoticcar2.jpg");
  min-height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}

@media (max-width: 800px){
  .defaultHero,
  .carsHero {
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
  }
  .carHero {
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
  }
}
/* End of Hero */

/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);

  font-style: italic;

}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 5rem 0;
}
.services {
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured cars */

.featured-cars {
  padding: 5rem 0;
}
.featured-cars-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-cars-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-cars-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured cars */
/* car */
.vehicle {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
  display: flex;
  flex-direction: column;
}
.car:hover {
  box-shadow: var(--darkShadow);
  
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
  
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
  
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.Car-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
  
}
.img-container:hover .price-top {
  opacity: 0;
  
}
.img-container:hover .Car-link {
  transform: translate(-50%, -50%) scale(1);
  
}
.Car-info {
  background: var(--darkGrey);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
 flex: 1;
font-style: italic;
  
}
/* end of car  */
/* single car*/

.single-car {
  
background-color: black;
    width:200px;
    height:200px;
 
 /*Scale down will take the necessary specified space that is 100px x 100px without stretching the image*/
     object-fit:scale-down;
     
 
 
}
.single-car-images {
  padding: 5rem 0 0 0;
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;

}
.single-car-images img {
  width: 100%;
  display: block;
  padding: 5rem 0 0 0;
  
}
.single-car-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
  padding: 5rem 0 0 0;
  
}
.desc,
.info {
  margin: 1rem 0;
  
  
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.car-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
  padding: 5rem 0 0 0;
}
.car-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-car-images,
  .single-car-info,
  .car-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-car-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single car*/
/* carlist */
.Carslist {
  padding: 5rem 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Carslist-center {
  width: 100vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
 
}

@media screen and (min-width: 776px) {
  .Carslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .Carslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of carlist */
/*  cars fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
  
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of cars fitler*/
.social-container {
  background: #eee;
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-2px);
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}
.hero {
  background: url("./images/exoticcar3.jpg") center/cover no-repeat fixed;
  min-height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .hero {
    background-position: top center;
    background-size: cover;
    background-attachment: scroll;
    height: calc(100vh - 66px);
  }
}
