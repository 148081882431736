@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
  font-size: 16px;
}
h1 {
  font-weight: normal;
  font-size: 1.3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  color: #222;
  color: var(--mainBlack);
  background: #af9a7d;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid #af9a7d;
  border: 3px solid var(--primaryColor);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: #af9a7d;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: #f7f7f7;
  background: var(--offWhite);
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  background-color: black;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: #af9a7d;
  color: var(--primaryColor);
}
.nav-links {
  height: auto;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  list-style-type: none;
  display: none; /* Hide the nav links by default */
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: #222;
  color: var(--mainBlack);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: #af9a7d;
  color: var(--primaryColor);
}

.show-nav {
  display: block; /* Show the nav links when the .show-nav class is added */
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    overflow: visible;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 4;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.carsHero {
  height: calc(100vh - 66px);
  width: 100%;
  background: url(/static/media/exoticcar3.d50b7df0.jpg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}

.carHero {
  background-image: url(/static/media/exoticcar2.0fac3b73.jpg);
  min-height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}

@media (max-width: 800px){
  .defaultHero,
  .carsHero {
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
  }
  .carHero {
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
  }
}
/* End of Hero */

/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);

  font-style: italic;

}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: #af9a7d;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: #af9a7d;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 5rem 0;
}
.services {
  background: #cfcfcf;
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: #af9a7d;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured cars */

.featured-cars {
  padding: 5rem 0;
}
.featured-cars-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-cars-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-cars-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured cars */
/* car */
.vehicle {
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--lightShadow);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  display: flex;
  flex-direction: column;
}
.car:hover {
  box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--darkShadow);
  
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--mainTransition);
  transition: var(--mainTransition);
  
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.Car-link {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
  
}
.img-container:hover .price-top {
  opacity: 0;
  
}
.img-container:hover .Car-link {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  
}
.Car-info {
  background: #cfcfcf;
  background: var(--darkGrey);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
 flex: 1 1;
font-style: italic;
  
}
/* end of car  */
/* single car*/

.single-car {
  
background-color: black;
    width:200px;
    height:200px;
 
 /*Scale down will take the necessary specified space that is 100px x 100px without stretching the image*/
     object-fit:scale-down;
     
 
 
}
.single-car-images {
  padding: 5rem 0 0 0;
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;

}
.single-car-images img {
  width: 100%;
  display: block;
  padding: 5rem 0 0 0;
  
}
.single-car-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
  padding: 5rem 0 0 0;
  
}
.desc,
.info {
  margin: 1rem 0;
  
  
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.car-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
  padding: 5rem 0 0 0;
}
.car-extras h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-car-images,
  .single-car-info,
  .car-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-car-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single car*/
/* carlist */
.Carslist {
  padding: 5rem 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Carslist-center {
  width: 100vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
 
}

@media screen and (min-width: 776px) {
  .Carslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .Carslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of carlist */
/*  cars fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid #222;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
  
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of cars fitler*/
.social-container {
  background: #eee;
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  -webkit-transition: -webkit-transform 250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  display: inline-block;
}
a.social:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}
.hero {
  background: url(/static/media/exoticcar3.d50b7df0.jpg) center/cover no-repeat fixed;
  min-height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .hero {
    background-position: top center;
    background-size: cover;
    background-attachment: scroll;
    height: calc(100vh - 66px);
  }
}

.social-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    
    font-size: 20px;
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .social:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  .icon {
   
    font-size: 20px;
  }
  
.calendar-container {
  position: relative;
  height: 0;
  padding-bottom: 50%; /* set the aspect ratio of the iframe */
}

.calendar-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 120px; /* adjust the top margin to move the iframe up */
}

@media (max-width: 767px) {
  /* styles for screens smaller than 768px */
  .calendar-container {
    padding-bottom: 120%; /* increase the aspect ratio for mobile screens */
    height: 0;
  }
  
  .calendar-container iframe {
    height: 110vw; /* adjust the height for mobile screens */
    margin-top: 120px; /* adjust the top margin to move the iframe up netlify */
    
  }
}
/*
=============== 
Fonts
===============
*/

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  /* --clr-primary-1: hsl(43, 86%, 17%); */
  --clr-primary-1: rgb(218,165,32);
  --clr-primary-2: rgb(218,165,32);
  --clr-primary-5: rgb(218,165,32);
  --clr-primary-6: rgb(0, 0, 0);

  /* darkest grey - used for headings */
  --clr-grey-1:black;
  --clr-white: #fff;
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(0, 0%, 0%);
  --clr-grey-8: hsl(0, 0%, 0%);
  --clr-grey-9: rgb(218,165,32);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-green-dark: hsl(125, 67%, 35%);
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-white: #fff;
  --ff-primary: "Roboto", sans-serif;
  --ff-secondary: "Open Sans", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.2rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 700px;
}

/* Global Styles */

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-family: var(--ff-secondary);
  background: #fff;
  background: var(--clr-white);
  color: black;
  color: var(--clr-grey-1);
  line-height: 1.75;
  font-size: 1rem;
  margin-top: 5rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

p {
  margin-bottom: 1.5rem;
  color: var(--clr-grey-3);
}

@media screen and (min-width: 800px) {
  body {
    font-size: 1.25rem;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: rgb(0, 0, 0);
  background: var(--clr-primary-6);
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
  padding: 0.375rem 0.75rem;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  -webkit-transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: all 0.3s linear;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.btn:hover {
  color: rgb(0, 0, 0);
  color: var(--clr-primary-6);
  background: rgb(218,165,32);
  background: var(--clr-primary-5);
}
.center-btn {
  display: block;
  width: 12rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
/* added during recording */
.underline {
  width: 5rem;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  background: rgb(218,165,32);
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.section-title {
  margin-bottom: 4rem;
  text-align: center;
}
.bg-grey {
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
}
/* page links */

/* social links */
.social-links {
  margin-top: 2rem;
  width: 15rem;
  display: flex;
  justify-content: space-between;
}
.social-link {
  font-size: 1.75rem;
  color: black;
  color: var(--clr-grey-1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.social-link:hover {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
}
/*
=============== 
Navbar
===============
*/
/*
=============== 
Sidebar
===============
*/
.sidebar {
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: grid;
  place-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.show-sidebar {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.sidebar-links li {
  opacity: 0;
}
.sidebar-links li a {
  display: block;
  text-align: center;
  text-transform: capitalize;
  color: hsl(0, 0%, 0%);
  color: var(--clr-grey-5);
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
  margin-bottom: 0.5rem;
  font-size: 2rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.sidebar-links li a:hover {
  background: rgb(218,165,32);
  background: var(--clr-primary-5);
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
}
.close-btn {
  position: absolute;
  right: 4.75%;
  top: 2.75%;
  font-size: 2.5rem;
  background: transparent;
  border-color: transparent;
  color: hsl(360, 67%, 44%);
  color: var(--clr-red-dark);
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .sidebar {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
/*
=============== 
Sidebar Animation
===============
*/
.sidebar-links li {
  -webkit-animation: slideRight 0.5s ease-in-out 0.3s forwards;
          animation: slideRight 0.5s ease-in-out 0.3s forwards;
}
.sidebar-links li:nth-of-type(1) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.sidebar-links li:nth-of-type(2) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sidebar-links li:nth-of-type(3) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.sidebar-links li:nth-of-type(4) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.sidebar-links li:nth-of-type(5) {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}
@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.sidebar-icons li {
  opacity: 0;
  -webkit-animation: slideUp 0.5s ease-in-out 0.3s forwards;
          animation: slideUp 0.5s ease-in-out 0.3s forwards;
}
.sidebar-icons li:nth-of-type(1) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.sidebar-icons li:nth-of-type(2) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.sidebar-icons li:nth-of-type(3) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.sidebar-icons li:nth-of-type(4) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.sidebar-icons li:nth-of-type(5) {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/*


/*
=============== 
Services
===============
*/
.service {
  background: #fff;
  background: var(--clr-white);
  padding: 3rem 1.5rem;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-align: center;
  -webkit-transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: all 0.3s linear;
  transition: var(--transition);
}
.service-icon {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.service .underline {
  width: 3rem;
  height: 0.12rem;
  -webkit-transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: all 0.3s linear;
  transition: var(--transition);
}
.service p {
  -webkit-transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: all 0.3s linear;
  transition: var(--transition);
}
.service:hover {
  background: rgb(218,165,32);
  background: var(--clr-primary-5);
  color: rgb(0, 0, 0);
  color: var(--clr-primary-6);
}
.service:hover p {
  color: rgb(0, 0, 0);
  color: var(--clr-primary-6);
}
.service:hover .underline {
  background: rgb(218,165,32);
  background: var(--clr-primary-5);
}
@media screen and (min-width: 676px) {
  .services-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .services-center {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/*
=============== 
Jobs
===============
*/

.jobs-center {
  width: 80vw;
  margin: 0 auto;
  max-width: 1170px;
  max-width: var(--max-width);
}
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4rem;
}
.job-btn {
  background: transparent;
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1.25rem;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
  margin: 0 0.5rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
  padding: 0.25rem 0;
  line-height: 1;
}
.job-btn:hover {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
  box-shadow: 0 2px rgb(218,165,32);
  box-shadow: 0 2px var(--clr-primary-5);
}
.active-btn {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
  box-shadow: 0 2px rgb(218,165,32);
  box-shadow: 0 2px var(--clr-primary-5);
}

.job-info h3 {
  font-weight: 400;
}
.job-info h4 {
  text-transform: uppercase;
  color: hsl(0, 0%, 0%);
  color: var(--clr-grey-5);
  background: rgb(218,165,32);
  background: var(--clr-grey-9);
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
}
.job-date {
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.job-desc {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem;
  align-items: center;
  margin-bottom: 1.25rem;
}
.job-desc p {
  margin-bottom: 0;
  color: var(--clr-grey-3);
}
.job-icon {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
}
@media screen and (min-width: 992px) {
  .jobs-center {
    width: 90vw;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem;
  }
  .btn-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .job-btn {
    margin-bottom: 1rem;
  }
  .active-btn {
    box-shadow: -2px 0 rgb(218,165,32);
    box-shadow: -2px 0 var(--clr-primary-5);
  }
  .job-btn:hover {
    box-shadow: -2px 0 rgb(218,165,32);
    box-shadow: -2px 0 var(--clr-primary-5);
  }
}

/*
=============== 
Projects
===============
*/
.projects {
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
}
.project {
  display: grid;
  margin-bottom: 4rem;
}
.project-img {
  border-top-left-radius: 0.25rem;
  border-top-left-radius: var(--radius);
  border-top-right-radius: 0.25rem;
  border-top-right-radius: var(--radius);
  height: 19rem;
  z-index: 1;
}
.project-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right bottom, from(rgb(218,165,32)), to(#222));
  background: -webkit-linear-gradient(top left, rgb(218,165,32), #222);
  background: linear-gradient(to bottom right, rgb(218,165,32), #222);
  background: -webkit-gradient(linear, left top, right bottom, from(var(--clr-primary-5)), to(#222));
  background: -webkit-linear-gradient(top left, var(--clr-primary-5), #222);
  background: linear-gradient(to bottom right, var(--clr-primary-5), #222);
  opacity: 0.85;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.project:hover .project-img::after {
  opacity: 0;
}
.project-info {
  background: #fff;
  background: var(--clr-white);
  padding: 1rem 2rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: 0.25rem;
  border-bottom-right-radius: var(--radius);
}
.project-number {
  display: inline-block;
  font-size: 1.25rem;
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
  margin-bottom: 0.75rem;
}
.project-info h3 {
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}
.project-desc {
  word-spacing: 15px;
  color: var(--clr-grey-3);
}
.project-stack {
  margin-bottom: 1rem;
}
.project-stack span,
.about-stack span {
  display: inline-block;
  background: rgb(218,165,32);
  background: var(--clr-grey-9);
  color: hsl(0, 0%, 0%);
  color: var(--clr-grey-5);
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.85rem;
}
.about-stack span {
  margin-top: 0.5rem;
}
.project-icon {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
  font-size: 1.25rem;
  margin-right: 0.5rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.project-icon:hover {
  color: rgb(0, 0, 0);
  color: var(--clr-primary-6);
}
@media screen and (min-width: 576px) {
  .project-img {
    height: 19rem;
  }
}
@media screen and (min-width: 768px) {
  .project-img {
    height: 22rem;
  }
}
@media screen and (min-width: 992px) {
  .project {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
  .project-img {
    grid-column: 1 / span 8;
    /* grid-column-end: 8; */
    grid-row: 1 / 1;
    height: 30rem;
    border-radius: 0.25rem;
    border-radius: var(--radius);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: var(--dark-shadow);
  }
  .project-info {
    border-radius: 0.25rem;
    border-radius: var(--radius);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: var(--dark-shadow);

    z-index: 1;
    grid-column: 5 /12;
    grid-row: 1 / 1;
  }
  .project:nth-of-type(even) .project-img {
    grid-column: 5 / -1;
    grid-row: 1 / 1;
  }
  .project:nth-of-type(even) .project-info {
    grid-column: 2 / span 7;
    grid-row: 1 / 1;
    text-align: left;
  }
}

/*
===============
Footer
===============
*/
.footer {
  height: 9rem;
  background: #222;
  text-align: center;
  display: grid;
  place-items: center;
}
.footer h4 {
  margin-top: 0.5rem;
  color: #fff;
  color: var(--clr-white);
  font-weight: normal;
  text-transform: uppercase;
}
.footer h4 span {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
}
.footer-links {
  margin: 0 auto 1rem auto;
}
.footer .social-link {
  color: #fff;
  color: var(--clr-white);
}
.footer .social-link:hover {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
}
/*
===============
Pages
===============
*/

.projects-page,
.error-page,
.about-page,
.contact-page {
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  min-height: calc(100vh - 5rem - 9rem);
}
.error-page {
  display: grid;
  place-items: center;
  background: rgb(218,165,32);
  background: var(--clr-primary-5);
  text-align: center;
}
.error-page h1 {
  text-transform: uppercase;
  color: rgb(218,165,32);
  color: var(--clr-primary-1);
  margin-bottom: 2rem;
}

/*
===============
About Page
===============
*/
.about-page {
  padding: 7rem 0;
}
.about-img {
  margin-bottom: 2rem;
  height: 25rem;
}
.about-text .section-title {
  margin-bottom: 2rem;
  text-align: left;
}
.about-text .underline {
  margin-left: 0;
  margin-right: 0;
}
.about-text p {
  line-height: 2;
}
.stack-container {
  width: 30rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}
.stack-container span {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: hsl(209, 28%, 39%);
  color: var(--clr-grey-4);
}
.stack-icon {
  color: rgb(218,165,32);
  color: var(--clr-primary-5);
  margin-right: 0.25rem;
  font-size: 1rem;
}
@media screen and (min-width: 992px) {
  .about-center {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
       -moz-column-gap: 4rem;
            column-gap: 4rem;
  }
  .about-img {
    grid-column: 1 / span 5;
    margin-bottom: 0;
  }
  .about-text {
    grid-column: 6 / -1;
  }
}
/*
===============
Contact Page
===============
*/
.contact-page {
  display: grid;
  place-items: center;
  padding: 5rem 0;
}
.contact-form {
  background: #fff;
  background: var(--clr-white);
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--light-shadow);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 90vw;
  max-width: 35rem;
}
.contact-form:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: var(--dark-shadow);
}
.contact-form h3 {
  padding-top: 1.25rem;
  color: hsl(0, 0%, 0%);
  color: var(--clr-grey-5);
}
.form-group {
  padding: 1rem 1.5rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  margin-bottom: 1.25rem;
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  border-radius: 0.25rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: black;
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control::-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: black;
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control:-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: black;
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control::-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: black;
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  color: black;
  color: var(--clr-grey-1);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  letter-spacing: var(--spacing);
}
.submit-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: 0.25rem;
  border-bottom-right-radius: var(--radius);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

