.social-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    
    font-size: 20px;
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
  }
  
  .social:hover {
    transform: translateY(-5px);
  }
  .icon {
   
    font-size: 20px;
  }
  